/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ByMinMaxReport_reportFragment_viewer$ref: FragmentReference;
declare export opaque type ByMinMaxReport_reportFragment_viewer$fragmentType: ByMinMaxReport_reportFragment_viewer$ref;
export type ByMinMaxReport_reportFragment_viewer = {|
  +minMaxReport: ?any,
  +id: string,
  +$refType: ByMinMaxReport_reportFragment_viewer$ref,
|};
export type ByMinMaxReport_reportFragment_viewer$data = ByMinMaxReport_reportFragment_viewer;
export type ByMinMaxReport_reportFragment_viewer$key = {
  +$data?: ByMinMaxReport_reportFragment_viewer$data,
  +$fragmentRefs: ByMinMaxReport_reportFragment_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterBy"
    },
    {
      "kind": "RootArgument",
      "name": "groupBy"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ByMinMaxReportRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ByMinMaxReport_reportFragment_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "count",
          "value": 5000
        },
        {
          "kind": "Variable",
          "name": "filterBy",
          "variableName": "filterBy"
        },
        {
          "kind": "Variable",
          "name": "groupBy",
          "variableName": "groupBy"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "kind": "ScalarField",
      "name": "minMaxReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '46eae12c9da6fd56ba758f4a4ebbda3b';

module.exports = node;
