import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';

import { RemoveAddressMutation, UpdateCustomerMutation, UpdateCustomerCreditMutation } from './mutations';
import CustomerForm from './CustomerForm';

class CustomerView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      customers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  deleteAddress = (address, customer) => {
    RemoveAddressMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id: address.id, customerId: customer.id } },
      parent: customer,
      viewer: this.props.viewer,
      safeGuard: "update",
    });
  }

  updateCredit = (values) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
    };
    UpdateCustomerCreditMutation.commit(mutation);
  }

  handleSubmit = (form, values) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateCustomerMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const customer = get(viewer, 'customers.edges[0].node', {});

    return (
      <div>
        <Helmet title={`${customer.firstname} ${customer.lastname} - Customer`} />
        <h1>{customer.name}</h1>
        <CustomerForm
          key={customer.id}
          match={match}
          viewer={viewer}
          customer={customer}
          onSubmit={this.handleSubmit}
          deleteAddress={this.deleteAddress}
          updateCredit={this.updateCredit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(CustomerView, {
  viewer: graphql`
    fragment CustomerView_viewer on Admin {
      ...AddressForm_viewer
      ...CustomerForm_viewer
      customers(first: 1, ids: $ids) {
        edges {
          node {
            id
            firstname
            lastname
            email
            reviewReminder
            checkedOrders
            securityCheck
            lastLoginAt
            subscription {
              status
            }
            status
            credits(first: 999) {
              edges {
                node {
                  id
                  name
                  creditId
                  creditAmount
                  creditRemaining
                  expiry
                  orderId
                  orderName
                  brands
                  spentOnOrderIds
                }
              }
            }
            salesOrders(first: 999) @connection(key: "CustomerView_salesOrders") {
              edges {
                node {
                  id
                  name
                  grandTotal
                  insertedAt
                }
              }
            }
            addresses(first: 99) @connection(key: "CustomerView_addresses") {
              edges {
                node {
                  id
                  firstname
                  lastname
                  street
                  city
                  postcode
                  region
                  country {
                    alpha2
                    name
                  }
                  telephone
                  company
                  fax
                }
              }
            }
            ...CustomerHistory_customer
          }
        }
      }
    }
  `,
});
