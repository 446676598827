import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateSubcategoryMutation($input: UpdateSubcategoryInput!) {
    updateSubcategory(input:$input) {
      subcategory {
        name
        urlSlug
        position
        aliases
        attributes
        metaTitle
        metaDescription
        descriptions (first: 999) {
          edges {
            node {
              id
              description
              description2
              brands {
                id
                name
              }
              categories {
                id
                name
              }
            }
          }
        }
        metadata (first: 999) {
          edges {
            node {
              id
              metaTitle
              metaDescription
              canonicalUrl {
                url
                status
              }
              brands {
                id
                name
              }
              categories {
                id
                name
              }
            }
          }
        }
        categories {
          id
          name
        }
        childSubcategories (first: 999) {
          edges {
            node {
              id
              name
            }
          }
        }
        subcategories {
          id
          name
        }
        autoAssignCategory {
          categoryId
          attributeId
          option
        }
        images (first: 999) {
          edges {
            node {
              id
              urlSlug
              mode
              link
              brands {
                id
                name
              }
              categories {
                id
                name
              }
              subcategories {
                id
                name
              }
            }
          }
        }
        status
        displayMode
        visibleFirstDepthOnly
        nestBrands {
          id
          name
        }
        nests {
          id
          name
        }
        parents {
          id
          name
        }
        ...SubcategoryHistory_subcategory
        ...WebsiteRef_subcategory
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
