import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get, unionBy } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Divider, Form, Input, InputNumber, Popconfirm, Select, Switch, Tabs, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, fileValidator, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { ShowLabel } from '~/components/promotion/label';
import BonusHistory from './BonusHistory';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

class BonusForm extends FormBase {
  static propTypes = {
    bonus: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      onProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
      quantityRequired: PropTypes.number,
    }),
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    remove: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    bonus: {},
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  addConfigProducts = (form, configProducts) => {
    if (configProducts.length > 0) {
      const onProducts = form.getFieldValue('onProducts');
      const options = configProducts.map((edge) => SelectProduct.productOptions(edge.node.product));
      const newOnProduct = unionBy(onProducts, options, 'key');

      form.setFieldsValue({
        onProducts: newOnProduct,
      });
    }
  }

  render() {
    const { match, viewer } = this.props;
    const bonus = get(this.props, 'bonus', {});
    const bonusProducts = get(bonus, 'products.edges', []);
    const images = get(bonus.images, 'edges', []);

    const initialValue = {
      bonusProducts: bonusProducts.length ? bonusProducts.slice(0).map(({ node }) => ({
        id: node.id,
        productId: SelectProduct.productOptions(node.product),
        price: get(node, 'price', 0),
        quantity: get(node, 'quantity', 1),
        optional: get(node, 'optional', false),
      })) : [{ productId: undefined, price: 0, quantity: 1, optional: false }],
      images: images.length ? images.slice(0).map(({ node }) => ({
        id: node.id,
        imageUrl: node.imageUrl,
      })) : [{}],
    };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values, { setLoading: this.setLoading }); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            {this.renderSaveButton()}
            {bonus.id && (
            <React.Fragment>
              <Popconfirm
                title="Are you sure delete this bonus? Related product images will be deleted as well!"
                onConfirm={()=> { this.props.remove(bonus); }}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={this.shouldDisableBtn()}>Delete</Button>
              </Popconfirm>
              <a href={`/api/promotion/download?id=${bonus.id}`} target="_blank" rel="noopener noreferrer">Download</a>
            </React.Fragment>
            )}
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>
        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={bonus.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
            >
              <FormItem
                name="name"
                rules={[{ required: true, message: 'required' }]}
                initialValue={bonus.name}
              >
                <Input placeholder="Name" />
              </FormItem>

              <ShowLabel labels={bonus.labels} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={bonus.startDate ? moment(bonus.startDate) : null}
            >
              <DatePicker showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={bonus.endDate ? moment(bonus.endDate) : null}
              help={<div>If promotion ends on the 2020-01-01, the input should be: <b>2020-01-01 23:59:59</b></div>}
            >
              <DatePicker showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }} placeholder="End Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="On Products"
              name="onProducts"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(bonus, 'onProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
            >
              <SelectProduct
                placeholder="On Products"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
                importExport={{
                  onImport: (value) => { SelectProduct.updateSelect(this.formRef.current, value, 'onProducts'); },
                  listToExport: get(bonus, 'onProducts.edges', [])
                }}
                onSelect={(value) => {
                  const configProducts = get(value.label.props, "product.configurables.edges", []);
                  this.addConfigProducts(this.formRef.current, configProducts);
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Quantity Required"
              name="quantityRequired"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(bonus, 'quantityRequired', 1)}
            >
              <InputNumber placeholder="quantity required" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Free Products"
            >
              <Form.List name="p" initialValue={initialValue.bonusProducts}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <div key={key}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => getFieldValue(["p", name, "id"]) ? (
                            <FormItem
                              name={[name, "id"]}
                              rules={[{ required: true, message: 'required' }]}
                              hidden
                            >
                              <Input />
                            </FormItem>
                          ) : null}
                        </FormItem>

                        <FormItem
                          label="Product"
                          name={[name, "productId"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '350px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <SelectProduct
                            placeholder="Products"
                            labelInValue
                            showSearch
                            filterOption={false}
                            viewer={viewer}
                          />
                        </FormItem>

                        <FormItem
                          label="Price"
                          name={[name, "price"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '90px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <InputNumber placeholder="Price" />
                        </FormItem>

                        <FormItem
                          label="Quantity"
                          name={[name, "quantity"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '90px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <InputNumber placeholder="Quantity" />
                        </FormItem>

                        <FormItem
                          label="Optional"
                          name={[name, "optional"]}
                          valuePropName="checked"
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '75px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Switch />
                        </FormItem>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ cursor: 'pointer' }}
                            disabled={fields.length === 1}
                            onClick={() => remove(name)}
                          />
                        ) : null}
                      </div>
                    ))}
                    <Button onClick={() => { add({ productId: undefined, price: 0, quantity: 1, optional: false }); }} >
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>

            {bonus.id && (
            <FormItem
              {...formItemLayout}
              label="Images"
              extra="Images show across all related products"
            >
              <Form.List name="images" initialValue={initialValue.images}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }, i) => (
                      <div key={key}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => getFieldValue(["images", name, "id"]) ? (
                            <FormItem
                              name={[name, "id"]}
                              rules={[{ required: true, message: 'required' }]}
                              hidden
                            >
                              <Input />
                            </FormItem>
                          ) : null}
                        </FormItem>

                        <FormItem
                          label={`Image ${i}`}
                        >
                          <FormItem noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              const imageUrl = getFieldValue(["images", name, "imageUrl"]);

                              return imageUrl ? (
                                <img alt="" src={imageUrl} width="120" height="120" />
                              ) : null
                            }}
                          </FormItem>

                          <MinusCircleOutlined
                            style={{ cursor: 'pointer' }}
                            disabled={fields.length === 1}
                            onClick={() => remove(name)}
                          />

                          <FormItem
                            name={[name, "file"]}
                            valuePropName="fileList"
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                            rules={[
                              { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) }
                            ]}
                          >
                            <Upload
                              accept="image/gif,image/png,image/jpeg"
                              beforeUpload={() => false}
                              listType="picture"
                            >
                              <Button>
                                <UploadOutlined /> Upload
                              </Button>
                            </Upload>
                          </FormItem>
                        </FormItem>

                        <Divider />
                      </div>
                    ))}

                    <Button onClick={() => add()}>
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="Stealth Mode"
              name="stealthMode"
              initialValue={get(bonus, 'stealthMode', 0) ? 1 : 0}
              extra="promotional messages will be hidden"
            >
              <Select placeholder="Stealth Mode">
                <Option value={1}>Yes</Option>
                <Option value={0}>No</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(bonus, 'status', 0) ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          {bonus.id && (
            <TabPane tab="History" key="history">
              <BonusHistory bonus={bonus} viewer={viewer} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(BonusForm, {
  viewer: graphql`
    fragment BonusForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      ...SelectProduct_viewer
      ...BonusHistory_viewer
    }
  `,
});
