/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SalesTargetReport_viewer$ref = any;
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type routes_SalesTarget_QueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>
|};
export type routes_SalesTarget_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SalesTargetReport_viewer$ref
  |}
|};
export type routes_SalesTarget_Query = {|
  variables: routes_SalesTarget_QueryVariables,
  response: routes_SalesTarget_QueryResponse,
|};
*/


/*
query routes_SalesTarget_Query(
  $filterBy: [FilterBy]
) {
  viewer {
    ...SalesTargetReport_viewer
    id
  }
}

fragment SalesTargetReport_viewer on Admin {
  salesTargetReport(filterBy: $filterBy)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SalesTarget_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SalesTargetReport_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_SalesTarget_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy"
              }
            ],
            "kind": "ScalarField",
            "name": "salesTargetReport",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7019c6ddee92a3ceb8e4671f7706bcb",
    "id": null,
    "metadata": {},
    "name": "routes_SalesTarget_Query",
    "operationKind": "query",
    "text": "query routes_SalesTarget_Query(\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    ...SalesTargetReport_viewer\n    id\n  }\n}\n\nfragment SalesTargetReport_viewer on Admin {\n  salesTargetReport(filterBy: $filterBy)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2e9f6fcd8de5734bce284c871d4d776';

module.exports = node;
