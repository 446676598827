import React from 'react';
import { get } from 'lodash';
import { Input, Select } from 'antd';
import CopyProduct from './CopyProduct';
import DatePicker from './DatePicker';
import DictatesAttribute from './DictatesAttribute';
import FormBase, { useFormBase } from './FormBase';
import QuickLink from './QuickLink';
import SelectProduct from './SelectProduct';
import SelectOrder from './SelectOrder';
import EditorInput from './EditorInput';
import { removeItem } from './helper';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 8 },
  },
};

const AuStates = {
  'ACT': 'Australia Capital Territory',
  'NSW': 'New South Wales',
  'NT': 'Northern Territory',
  'QLD': 'Queensland',
  'SA': 'South Australia',
  'TAS': 'Tasmania',
  'VIC': 'Victoria',
  'WA': 'Western Australia',
};

const NzStates = {
  'NORTH ISLAND': 'North Island',
  'SOUTH ISLAND': 'South Island',
};

const getCountry = (entity) => {
  let defaultCountry = 'AU';

  if (window.location.host.includes('co.nz')) {
    defaultCountry = 'NZ';
  }

  return get(entity, 'country.alpha2', defaultCountry);
}

const getStates = () => {
  if (window.location.host.includes('co.nz')) {
    return NzStates;
  }

  return AuStates;
}

const getStateOrAreaManager = function getStateOrAreaManager(plural = false) {
  let s = '';
  let managerType = 'State Manager';

  if (plural) {
    s = 's'
  }

  if (window.location.host.includes('co.nz')) {
    managerType = 'Area Manager'
  }

  return managerType.concat(s);
}

const getSuburbOrCityLabel = function getSuburbOrCityLabel() {
  return window.location.host.includes('co.nz') ? (
    "Suburb"
  ) : (
    "City"
  )
}

const renderRegion = function renderRegion() {
  return window.location.host.includes('co.nz') ? (
    <Input placeholder="Town / City" />
  ) : (
    <Select
      placeholder="State"
      options={Object.entries(getStates()).map(([k, v]) => ({ value: k, label: v }))}
    />
  )
}

const fileValidator = (mb = 2, rule, files) => {
  if (Array.isArray(files)) {
    const overSizeFile = files.find(file => {
      const isLt = file.size / 1024 / 1024 < mb;
      return !isLt;
    });

    if (overSizeFile) {
      return Promise.reject(new Error(`file must be smaller than ${mb}MB!`));
    }
  }
  else if (typeof(files) === "object" && files.file) {
    const isLt = files.file.size / 1024 / 1024 < mb;
    if (!isLt) {
      return Promise.reject(new Error(`file must be smaller than ${mb}MB!`));
    }
  }
  return Promise.resolve();
};

const checkSize = async (file, sizes) => new Promise((resolve) => {
  const img = new Image();

  img.src = window.URL.createObjectURL(file);

  img.onload = () => {
    const width = img.naturalWidth;
    const height = img.naturalHeight;
    window.URL.revokeObjectURL( img.src );

    const any = sizes.some(([maxWidth, maxHeight]) => (
      width === maxWidth && height === maxHeight
    ));

    resolve(any);
  };
});

const imageValidator = async (size, rule, files) => {
  if (Array.isArray(files)) {
    let checks = files.map((file) => (
      checkSize(file.originFileObj, size)
    ));

    checks = await Promise.all(checks);

    if (checks.some((d) => d === false)) {
      return Promise.reject(new Error(rule.message));
    }
  }
  else if (typeof(files) === "object" && files.file) {
    const check = await checkSize(files.file.originFileObj, size);

    if (!check) {
      return Promise.reject(new Error(rule.message));
    }
  }

  return Promise.resolve();
};

const isDataEntry = (viewer) => {
  return get(viewer, 'roles.edges', []).some(({ node }) => node.name === 'Data Entry');
};

export {
  CopyProduct,
  DatePicker,
  DictatesAttribute,
  useFormBase,
  FormBase,
  QuickLink,
  SelectProduct,
  SelectOrder,
  EditorInput as Editor,
  fileValidator,
  formItemLayout,
  formItemLayoutWithOutLabel,
  getCountry,
  getStates,
  getStateOrAreaManager,
  getSuburbOrCityLabel,
  renderRegion,
  removeItem,
  imageValidator,
  isDataEntry,
};
