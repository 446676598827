import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useFragment, useRefetchableFragment } from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, groupBy, isEqual } from 'lodash';
import { Form, Menu, Select, Table, TreeSelect } from 'antd';
import moment from 'moment-timezone';

import { DatePicker } from '~/components/form';
import { currencyFormatter } from '~/components/grid';
import { usePrevious } from '~/helper';
import { LoadingTable, selectFragment, transformColumn } from './ByMinMaxReport';
import { DateShortcuts, YearToDate, RenderCustomDate } from './helper';

import "./style.css";

const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;

const entityName = 'Revenue Report';

const reportQuery = graphql`
  fragment RevenueReport_viewer on Admin
  @refetchable(queryName: "RevenueReportRefetchQuery") {
    revenueReport(filterBy: $filterBy, type: $type)
  }
`;

const getCellColor = (value) => {
  if (value < 0) {
    return "color-neg";
  }
  return "color-pos";
};

const getTableColumns = (data, reportType) => {
  const sortDirections = ["descend", "ascend"];

  return get(data, 'revenueReport.header', []).map((key, keyIndex) => {
    let className = null;
    if (reportType === "monthly") {
      const mod = (keyIndex - 3) % 4;
      if (mod === 0) {
        className = "border-l-bold";
      } else if (mod > 0) {
        className = "border-b-bold";
      }
    } else if (reportType === "yearly") {
      const mod = (keyIndex === 3) ? 0 : ((keyIndex - 4) % 3)

      if (keyIndex > 2 && mod === 0) {
        className = "border-l-bold";
      } else if (mod > 0) {
        className = "border-b-bold";
      }
    }

    let header = { key, className };

    if (keyIndex < 3) {
      header = {
        ...header,
        title: key,
        render: (text, { record }) => record[keyIndex],
      }
    } else if (keyIndex >= 3 && key.includes("Diff +/-")) {
      header = {
        ...header,
        title: key,
        sorter: ({ record: recordA }, { record: recordB }) => {
          return recordA[keyIndex] - recordB[keyIndex]
        },
        sortDirections,
        render: (text, { record }) => {
          if (record[keyIndex]) {
            const cellClassName = getCellColor(record[keyIndex]);

            return (
              <span className={cellClassName}>
                {currencyFormatter({ value: record[keyIndex] })}
              </span>
            )
          }

          return null;
        },
      };
    } else if (keyIndex >= 3 && key.includes("Diff %")) {
      header = {
        ...header,
        title: key,
        sorter: ({ record: recordA }, { record: recordB }) => {
          return recordA[keyIndex] - recordB[keyIndex]
        },
        sortDirections,
        render: (text, { record }) => {
          if (record[keyIndex]) {
            const cellClassName = getCellColor(record[keyIndex]);

            return (
              <span className={cellClassName}>
                {`${record[keyIndex]}%`}
              </span>
            )
          }

          return null;
        },
      };
    } else {
      header = {
        ...header,
        title: key,
        sorter: ({ record: recordA }, { record: recordB }) => {
          return recordA[keyIndex] - recordB[keyIndex]
        },
        sortDirections,
        render: (text, { record }) => currencyFormatter({ value: record[keyIndex] }),
      };
    }

    return header;
  }).map(transformColumn);
};

const RevenueTable = (props) => {
  const { tableRef, reportType } = props;
  const [data, refetch] = useRefetchableFragment(reportQuery, props.viewer);

  useEffect(() => {
    tableRef.current = { data, refetch };
  }, [data, refetch]);

  const columns = getTableColumns(data, reportType);
  const dataSource = get(data, 'revenueReport.body', []).map((record) => ({ key: record[1], record }));

  const summary = () => {
    const header = get(data, 'revenueReport.header', []);
    const footer = get(data, 'revenueReport.footer', []);

    return (
      <Table.Summary.Row>
        {footer.map((f, index) => {
          const head = header[index];
          const className = head.includes("Diff") ? getCellColor(f) : null;

          let value = f;

          if (f && head.includes("Diff %")) {
            value = `${f}%`
          } else if (typeof f === 'number') {
            value = currencyFormatter({ value: f });
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Table.Summary.Cell className={className} key={index} index={index}>
              {value}
            </Table.Summary.Cell>
          )
        })}
      </Table.Summary.Row>
    )
  };

  return (
    <Table
      style={{ marginTop: '10px' }}
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 1200 }}
      pagination={false}
      size="small"
      summary={summary}
    />
  )
};

RevenueTable.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  tableRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }).isRequired,
  reportType: PropTypes.string,
};

RevenueTable.defaultProps = {
  reportType: null,
};

const RevenueReport = (props) => {
  const selectData = useFragment(selectFragment, props.viewer);

  const stores = get(selectData, 'stores.edges', []);
  const grouped = groupBy(stores, ({ node }) => node.state);
  const groupedKeys = Object.keys(grouped);

  const treeData = useMemo(() => {
    return [{
      value: 'all',
      title: 'All Stores',
      children: groupedKeys.sort().map((k) => ({
        value: k,
        title: k,
        children: grouped[k].map(({ node }) => ({ value: node.branchCode, title: `${node.name} (${node.branchCode})` }))
      })),
    }];
  }, [stores]);


  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("monthly");
  const [datePickerValue, setDatePickerValue] = useState(null);
  const [rangePickerValue, setRangePickerValue] = useState(null);
  const [ytdValue, setYtdValue] = useState(null);
  const [treeValue, setTreeValue] = useState(['all']);
  const [filters, setFilters] = useState({
    branch_code: { field: "branch_code", filter: "", filterType: "text", type: "inRange" },
  });
  const prevFilters = usePrevious(filters);

  useEffect(() => {
    if (!isEqual(prevFilters, filters)) {
      const { refetch } = tableRef.current;
      const filterBy = Object.values(filters);

      setLoading(true);
      refetch({ filterBy, type: reportType }, {
        fetchPolicy: 'store-and-network',
        onComplete: () => { setLoading(false); },
      });
    }
  }, [tableRef, prevFilters, filters, reportType]);

  const onDateChange = (date) => {
    const key = "created_at";
    setDatePickerValue(date);

    if (date) {
      const startDate = moment(date).startOf('month').utc().format();
      const endDate = moment(date).endOf('month').utc().format();
      const filter = `${startDate},${endDate}`;

      setFilters((s) => ({
        ...s,
        [key]: { field: key, filter, filterType: "text", type: "inRange" }
      }));
    } else {
      setFilters((s) => {
        const { [key]: _, ...newState } = s;
        return newState;
      });
    }
  };

  const onRangeChange = (dateRange) => {
    const key = "created_at";
    const ytdKey = "year_to_date";
    setRangePickerValue(dateRange);

    if (Array.isArray(dateRange)) {
      let filter;
      const sameMonth = dateRange[0].format() === dateRange[1].format();

      if (reportType === "monthly") {
        const startDate = moment(dateRange[0]).startOf('month').utc().format();
        const endDate = moment(dateRange[1]).endOf('month').utc().format();

        filter = `${startDate},${endDate}`;
      } else if (reportType === "yearly") {
        const startDate = moment(dateRange[0]).startOf('year').utc().format();
        const endDate = moment(dateRange[1]).endOf('year').utc().format();

        filter = `${startDate},${endDate}`;
      }

      if (!sameMonth) {
        setYtdValue(null);
      }

      setFilters((s) => {
        let newState = s;

        if (!sameMonth) {
          const { [ytdKey]: _, ...rest } = newState;
          newState = rest
        }

        return {
          ...newState,
          [key]: { field: key, filter, filterType: "text", type: "inRange" }
        }
      });
    } else {
      setFilters((s) => {
        const { [key]: _, [ytdKey]: __, ...newState } = s;
        return newState;
      });
    }
  };

  const onYtdChange = (dateRange) => {
    const key = "year_to_date";
    setYtdValue(dateRange);

    if (Array.isArray(dateRange)) {
      const startDate = moment(dateRange[0]).utc().format();
      const endDate = moment(dateRange[1]).utc().format();

      const filter = `${startDate},${endDate}`;

      setFilters((s) => ({
        ...s,
        [key]: { field: key, filter, filterType: "text", type: "inRange" }
      }));
    } else {
      setFilters((s) => {
        const { [key]: _, ...newState } = s;
        return newState;
      });
    }
  };

  const onBrandChange = (values) => {
    const key = "brand_name"
    const filter = { field: key, filter: values.join(), filterType: "text", type: "inRange" };

    if (values.length > 0) {
      setFilters((s) => ({ ...s, [key]: filter }));
    } else {
      setFilters((s) => {
        const { [key]: _, ...newState } = s;
        return newState;
      });
    }
  };

  const onTreeChange = (value) => {
    const key = "branch_code";
    setTreeValue(value);

    if (value[0] === undefined) {
      setFilters((s) => {
        const { [key]: _, ...newState } = s;
        return newState;
      });
    } else if (value[0] === 'all') {
      const filter = { field: key, filter: "", filterType: "text", type: "inRange" };
      setFilters((s) => ({ ...s, [key]: filter }));
    } else {
      const branchCodeFilter = value.flatMap((v) => {
        if (groupedKeys.includes(v)) {
          return grouped[v].map(({ node }) => node.branchCode);
        }
        return v;
      });

      const filter = { field: key, filter: branchCodeFilter.join(","), filterType: "text", type: "inRange" };
      setFilters((s) => ({ ...s, [key]: filter }));
    }
  };

  const renderPicker = () => {
    const style = { width: '50%', marginTop: '10px' };

    if (reportType === "daily") {
      return (
        <DatePicker
          style={style}
          value={datePickerValue}
          onChange={onDateChange}
          picker="month"
        />
      )
    } else if (reportType === "monthly") {
      const menu = (
        <Menu style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', 'flexDirection': 'column' }}>
          <Menu.Item key="yearToDate">
            <YearToDate setDateRange={onYtdChange} />
          </Menu.Item>
          <Menu.Item key="customDateMonths">
            <RenderCustomDate indicator='months' setDateRange={onYtdChange} loading={loading} />
          </Menu.Item>
        </Menu>
      );

      let disabled = true;

      if (Array.isArray(rangePickerValue)) {
        if (rangePickerValue[0].format() === rangePickerValue[1].format()) {
          disabled = false;
        }
      }

      return (
        <>
          <RangePicker
            style={style}
            value={rangePickerValue}
            onChange={onRangeChange}
            picker="month"
          />
          <Form disabled={disabled}>
            <Form.Item
              label="YTD"
              style={{ width: '50%', marginBottom: '0px', marginTop: '10px' }}
              help={
                <>
                  Year to Date calculation is only available when <b>Start</b> and <b>End</b> Month are the same.
                </>
              }
            >
              <RangePicker
                style={{ width: 'calc(100% - 136px)' }}
                value={ytdValue}
                onChange={onYtdChange}
              />
              <DateShortcuts menu={menu} />
            </Form.Item>
          </Form>
        </>
      )
    } else if (reportType === "yearly") {
      return (
        <RangePicker
          style={style}
          value={rangePickerValue}
          onChange={onRangeChange}
          picker="year"
        />
      )
    }

    return null;
  };

  return (
    <div>
      <Helmet title={entityName} />
      <h1>{entityName}</h1>

      <Select
        style={{ width: '50%', display: 'block' }}
        value={reportType}
        onChange={(value) => {
          onDateChange(null);
          onRangeChange(null);
          setReportType(value);
        }}
        options={[
          { value: "daily", label: "Daily Report" },
          { value: "monthly", label: "Month on Month Report" },
          { value: "yearly", label: "Year on Year Report" },
        ]}
      />

      {renderPicker()}

      <TreeSelect
        style={{ width: '50%', display: 'block', marginTop: '10px' }}
        treeDefaultExpandedKeys={['all']}
        treeData={treeData}
        value={treeValue}
        onChange={onTreeChange}
        showCheckedStrategy={SHOW_PARENT}
        placeholder="By Stores"
        filterTreeNode={(input, treeNode) => treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        treeCheckable
        allowClear
      />

      <Select
        mode="multiple"
        style={{ width: '50%', display: 'block', marginTop: '10px' }}
        placeholder="By Brand"
        options={get(selectData, 'brands.edges', []).map(({ node }) => ({ value: node.name, label: node.name }))}
        onChange={onBrandChange}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        allowClear
        showSearch
      />

      <React.Suspense fallback={<LoadingTable />}>
        <RevenueTable
          tableRef={tableRef}
          viewer={props.viewer}
          reportType={reportType}
        />
      </React.Suspense>
    </div>
  )
};

RevenueReport.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
};

export default RevenueReport;

