/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateStateManagerInput = {|
  areaManagers?: ?$ReadOnlyArray<?InputAreaManager>,
  emails?: ?$ReadOnlyArray<?string>,
  id?: ?string,
  state: string,
|};
export type InputAreaManager = {|
  email: string,
  id?: ?string,
  stores: $ReadOnlyArray<?string>,
|};
export type UpdateStateManagerMutationVariables = {|
  input: UpdateStateManagerInput
|};
export type UpdateStateManagerMutationResponse = {|
  +updateStateManager: ?{|
    +stateManager: ?{|
      +emails: ?$ReadOnlyArray<?string>,
      +state: ?string,
      +areaManagers: ?$ReadOnlyArray<?{|
        +id: string,
        +email: ?string,
        +stores: ?$ReadOnlyArray<?{|
          +id: string
        |}>,
      |}>,
    |}
  |}
|};
export type UpdateStateManagerMutation = {|
  variables: UpdateStateManagerMutationVariables,
  response: UpdateStateManagerMutationResponse,
|};
*/


/*
mutation UpdateStateManagerMutation(
  $input: UpdateStateManagerInput!
) {
  updateStateManager(input: $input) {
    stateManager {
      emails
      state
      areaManagers {
        id
        email
        stores {
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AreaManager",
  "kind": "LinkedField",
  "name": "areaManagers",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Store",
      "kind": "LinkedField",
      "name": "stores",
      "plural": true,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateStateManagerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStateManagerPayload",
        "kind": "LinkedField",
        "name": "updateStateManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManager",
            "kind": "LinkedField",
            "name": "stateManager",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateStateManagerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStateManagerPayload",
        "kind": "LinkedField",
        "name": "updateStateManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManager",
            "kind": "LinkedField",
            "name": "stateManager",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "132ee5a0d155d109f8053182314ab600",
    "id": null,
    "metadata": {},
    "name": "UpdateStateManagerMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateStateManagerMutation(\n  $input: UpdateStateManagerInput!\n) {\n  updateStateManager(input: $input) {\n    stateManager {\n      emails\n      state\n      areaManagers {\n        id\n        email\n        stores {\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b289909e5bff13596c51d64aac93e2e3';

module.exports = node;
