import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Item: FormItem } = Form;
const { Option } = Select;

const SubcategoryMetadata = (props) => {
  const { subcategory: { metadata }, viewer } = props;
  const brands = get(viewer, 'brands.edges', []);
  const categories = get(viewer, 'categories.edges', []);

  const defaultValue = {
    categoryIds: [],
    brandIds: [],
    metaTitle: '',
    metaDescription: '',
    canonicalUrl: {
      url: '',
      status: undefined,
    }
  }

  const initialValue = metadata.edges.length ? metadata.edges.slice(0).map(({ node }) => ({
    id: node.id,
    categoryIds: get(node, 'categories', []).map(p => ({ key: p.id, label: p.name })),
    brandIds: get(node, 'brands', []).map(p => ({ key: p.id, label: p.name })),
    metaTitle: get(node, 'metaTitle', ''),
    metaDescription: get(node, 'metaDescription', ''),
    canonicalUrl: {
      url: get(node, 'canonicalUrl.url', ''),
      status: get(node, 'canonicalUrl.status') || undefined,
    }
  })) : [];

  return (
    <Form.List name="metadata" initialValue={initialValue}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => (
            <div key={key}>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => getFieldValue(["metadata", name, "id"]) ? (
                  <FormItem
                    name={[name, "id"]}
                    rules={[{ required: true, message: 'required' }]}
                    hidden
                  >
                    <Input />
                  </FormItem>
                ) : null}
              </FormItem>

              <Row>
                <Col xs={24}>
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      style={{ cursor: 'pointer' }}
                      disabled={fields.length === 1}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Col>
                <Col xs={12}>
                  <FormItem
                    label="On Categories"
                    name={[name, "categoryIds"]}
                    rules={[{ required: false, message: 'required' }]}
                    style={{ margin: '0px' }}
                  >

                    <Select
                      placeholder="Categories"
                      mode="multiple"
                      optionFilterProp="children"
                      optionLabelProp="children"
                      labelInValue
                    >
                      {categories.map(({ node: o }) => (
                        <Option key={o.id} value={o.id}>{o.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>

                <Col xs={12}>
                  <FormItem
                    label="On Brands"
                    name={[name, "brandIds"]}
                    rules={[{ required: false, message: 'required' }]}
                    style={{ margin: '0px' }}
                  >
                    <Select
                      placeholder="Brands"
                      mode="multiple"
                      optionFilterProp="children"
                      optionLabelProp="children"
                      labelInValue
                    >
                      {brands.map(({ node: o }) => (
                        <Option key={o.id} value={o.id}>{o.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
              </Row>

              <FormItem
                label="Meta Title"
                name={[name, "metaTitle"]}
              >
                <Input placeholder="Meta Title" />
              </FormItem>
              <FormItem
                label="Meta Description"
                name={[name, "metaDescription"]}
              >
                <Input placeholder="Meta Description" />
              </FormItem>
              <FormItem
                label="Canonical Url"
                name={[name, "canonicalUrl", "url"]}
                hasFeedback
              >
                <Input placeholder="Canonical Url" />
              </FormItem>
              <FormItem
                name={[name, "canonicalUrl", "status"]}
                hidden
              >
                <Input />
              </FormItem>
              <Divider />
            </div>
          ))}
          <Button onClick={() => add(defaultValue)}>
            <PlusOutlined />
          </Button>
        </>
      )}
    </Form.List>
  )
};

SubcategoryMetadata.propTypes = {
  viewer: PropTypes.shape({
    brands: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
  subcategory: PropTypes.shape({
    metadata: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default createFragmentContainer(SubcategoryMetadata, {
  viewer: graphql`
    fragment SubcategoryMetadata_viewer on Admin {
      brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
