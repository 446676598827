import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation AddAdminUserMutation($input: AddAdminUserInput!) {
    addAdminUser(input:$input) {
      adminUserEdge {
        cursor
        node {
          id
          username
          firstname
          lastname
          email
          status
          roles {
            id
            name
          }
          permissions {
            id
            obj
            act
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
