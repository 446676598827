import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateStateManagerMutation($input: UpdateStateManagerInput!) {
    updateStateManager(input:$input) {
      stateManager {
        emails
        state
        areaManagers {
          id
          email
          stores {
            id
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
