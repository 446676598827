import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Affix, Button, Form, Input, InputNumber, List, Modal, Popconfirm, Select, Switch, Table, Tabs } from 'antd';
import moment from 'moment-timezone';
import { DatePicker, FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

import { fromGlobalId } from '../../helper';
import AddressForm from './AddressForm';
import CustomerHistory from './CustomerHistory';

const { Item: FormItem } = Form;
const { TabPane } = Tabs;
const { Option } = Select;

class CustomerForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
    }).isRequired,
    customer: PropTypes.shape({
      addresses: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      credits: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      customers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    onSubmit: PropTypes.func.isRequired,
    deleteAddress: PropTypes.func,
    updateCredit: PropTypes.func,
  }

  static defaultProps = {
    updateCredit: null,
    deleteAddress: null,
    customer: {},
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      addressFormVisible: false,
      address: null,
      editState: {},
      creditCache: {},
      expiryCache: {},
    };
  }

  editAddress = (address) => {
    this.setState({
      addressFormVisible: true,
      address,
    });
  }

  edit = (id) => {
    const { editState } = this.state;
    editState[id] = true;
    this.setState({ editState });
  }

  cancel = (id) => {
    const { editState } = this.state;
    editState[id] = false;
    this.setState({ editState });
  }

  afterAddressUpdate = () => {
    this.setState({
      addressFormVisible: false,
    });
  }

  save = (id, attr = "creditRemaining") => {
    const { editState } = this.state;
    editState[id] = false;
    this.setState({ editState });

    let cacheName = null;
    if (attr === "creditRemaining") {
      cacheName = "creditCache";
    } else if (attr === "expiry") {
      cacheName = "expiryCache";
    }

    const value = this.state[cacheName][id];

    if (cacheName === "creditCache" && typeof value === 'number') {
      this.props.updateCredit({ id, [attr]: value });
    } else if (cacheName === "expiryCache" && typeof value === 'object') {
      this.props.updateCredit({ id, [attr]: value });
    }
  }

  renderAddresses = (addresses, customer) => (
    <List
      size="small"
      bordered
      dataSource={addresses}

      renderItem={(edge) => {
        const address = edge.node;
        return (
          <List.Item actions={[
            <Button key="edit-button" onClick={() => { this.editAddress(address); }}>Edit</Button>,
            <Popconfirm
              key="delete-button"
              title="Are you sure delete this address?"
              onConfirm={()=> { this.props.deleteAddress(address, customer); }}
              okText="Yes"
              cancelText="No"
            >
              <Button>Delete</Button>
            </Popconfirm>,
          ]}
          >
            <List.Item.Meta
              title={`${address.firstname} ${address.lastname}`}
              description={this.renderAddress(address)}
            />
          </List.Item>
        );
      }}
    />
  )

  renderOrders = orders => ( // eslint-disable-line react/display-name
    <Table
      dataSource={orders}
      rowKey={record => record.node.id}
      columns={[
        {
          title: 'View',
          dataIndex: 'node',
          render: node => <a href={`/sales/order/${fromGlobalId(node.id).id}`} rel="noopener noreferrer" target="_blank">{node.name}</a>, // eslint-disable-line react/display-name
        },
        {
          title: 'Purchased On',
          dataIndex: ['node', 'insertedAt'],
          render: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
          title: 'Grand Total',
          dataIndex: ['node', 'grandTotal'],
          render: value => `$${value}`,
        },
      ]}
    />
  )

  renderAddress = (address) => { // eslint-disable-line react/display-name
    if (address) {
      return (
        <div>
          <p>{address.telephone}</p>
          <p>
            {address.street} {address.city} {address.postcode}
          </p>
          <p>
            {address.region} {address.country.name}
          </p>
          <p>
            {address.company} {address.fax}
          </p>

        </div>
      );
    }

    return null;
  }

  render() {
    const { match, viewer, customer } = this.props;
    const addresses = get(customer, 'addresses.edges', []);
    const credits = get(customer, 'credits.edges', []);
    const orders = get(customer, 'salesOrders.edges', []);

    const { address, addressFormVisible } = this.state;

    return (
      <div>
        <Modal
          title="Address"
          visible={addressFormVisible}
          onCancel={() => this.setState({ addressFormVisible: false, address: null })}
          footer={null}
        >
          <AddressForm viewer={viewer} address={address} updateAddress={this.afterAddressUpdate} />
        </Modal>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>
              <Affix>
                <div>
                  <Presence match={match} disableButton={this.handleDisableBtn} />
                  <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
                </div>
              </Affix>

              <FormItem
                name="id"
                initialValue={customer.id}
                hidden
              >
                <Input />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="First Name"
                name="firstname"
                rules={[{ required: true, message: 'required' }]}
                initialValue={customer.firstname}
              >
                <Input placeholder="First Name" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Last Name"
                name="lastname"
                rules={[{ required: true, message: 'required' }]}
                initialValue={customer.lastname}
              >
                <Input placeholder="Last Name" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Email"
                name="email"
                rules={[{ required: true, message: 'required' }, { type: 'email', message: 'Invalid Email' }]}
                initialValue={customer.email}
              >
                <Input placeholder="Email" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Subscribe to Newsletter"
                name="subscriptionStatus"
                valuePropName="checked"
                rules={[{ required: true, message: 'required' }]}
                initialValue={get(customer.subscription, 'status') === 1}
                extra="It syncs to Mailchimp"
              >
                <Switch />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Review Reminder"
                name="reviewReminder"
                valuePropName="checked"
                initialValue={get(customer, 'reviewReminder', true)}
              >
                <Switch />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Apply Security Check"
                name="securityCheck"
                valuePropName="checked"
                initialValue={get(customer, 'securityCheck', true)}
                extra="If enabled, it will be disabled when customers verify next order"
              >
                <Switch />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Security Checked Orders"
                name="checkedOrders"
                initialValue={get(customer, 'checkedOrders')}
                extra="set a negative large number(-999) to make this customer performs 1000 times security checks"
              >
                <InputNumber />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Last Log In"
              >
                {get(customer, 'lastLoginAt', 'xx')}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Status"
                name="status"
                rules={[{ required: true, message: 'required' }]}
                initialValue={customer.status}
              >
                <Select placeholder="Status">
                  <Option value="enabled">Enabled</Option>
                  <Option value="disabled">Disabled</Option>
                </Select>
              </FormItem>
            </Form>
          </TabPane>

          <TabPane tab="Orders" key="orders">
            {this.renderOrders(orders)}
          </TabPane>

          <TabPane tab="Addresses" key="address">
            {this.renderAddresses(addresses, customer)}
          </TabPane>

          <TabPane tab="Credits" key="credit">
            <Table
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'node',
                  key: 'name',
                  render: node => <a href={`/promotion/credit/${fromGlobalId(node.creditId).id}`} rel="noopener noreferrer" target="_blank">{node.name}</a>, // eslint-disable-line react/display-name
                },
                {
                  title: 'From Order',
                  dataIndex: 'node',
                  key: 'orderName',
                  render: node => <a href={`/sales/order/${node.orderId}`} rel="noopener noreferrer" target="_blank">{node.orderName}</a>, // eslint-disable-line react/display-name

                },
                {
                  title: 'On Orders',
                  dataIndex: 'node',
                  key: 'spentOnOrderIds',
                  render: node => (
                    node.spentOnOrderIds.map(id => {
                      const orderId = fromGlobalId(id).id;

                      return <a key={id} style={{display: 'block'}} href={`/sales/order/${orderId}`} rel="noopener noreferrer" target="_blank">ST{orderId}</a>; // eslint-disable-line react/display-name
                    })
                  )
                },
                {
                  title: 'Credit Earned',
                  dataIndex: ['node', 'creditAmount'],
                  key: 'creditAmount',
                  render: (value) => `$${value}`,
                },
                {
                  title: 'Credit Remaining',
                  dataIndex: ['node', 'creditRemaining'],
                  key: 'creditRemaining',
                  render: (value, record) => { // eslint-disable-line react/display-name
                    const { id } = record.node;
                    const editable = this.state.editState[id] || false;
                    return (
                      <div>
                        {editable ? (
                          <div>
                            <InputNumber
                              defaultValue={value}
                              onChange={(v) => {
                                const { creditCache } = this.state;
                                creditCache[id] = v;
                                this.setState({ creditCache });
                              }}
                            />

                            <Button onClick={() => this.save(id)}>Save</Button> <Button onClick={() => this.cancel(id)}>Cancel</Button>
                          </div>
                        ) : <div>${value} <Button onClick={() => this.edit(id)}>Edit</Button></div>
                        }
                      </div>
                    );
                  },
                },
                {
                  title: 'Expiry',
                  dataIndex: ['node', 'expiry'],
                  key: 'expiry',
                  render: (text, record) => { // eslint-disable-line react/display-name
                    const { id } = record.node;
                    const value = moment(text);
                    const editable = this.state.editState[id] || false;

                    return (
                      <div>
                        {editable ? (
                          <div>
                            <DatePicker
                              defaultValue={value}
                              onChange={(date) => {
                                const { expiryCache } = this.state;
                                expiryCache[id] = date.local().endOf('day');
                                this.setState({ expiryCache });
                              }}
                            />

                            <Button onClick={() => this.save(id, "expiry")}>Save</Button> <Button onClick={() => this.cancel(id)}>Cancel</Button>
                          </div>
                        ) : <div>{value.local().format('YYYY-MM-DD HH:mm:ss')} Sydney Time<Button onClick={() => this.edit(id)}>Edit</Button></div>
                        }
                      </div>
                    );
                  },
                },
              ]}
              dataSource={credits}
              rowKey={row => row.node.id}
            />
          </TabPane>

          {customer.id && (
            <TabPane tab="History" key="history">
              <CustomerHistory viewer={viewer} customer={customer} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default createFragmentContainer(CustomerForm, {
  viewer: graphql`
    fragment CustomerForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      ...AddressForm_viewer
      ...CustomerHistory_viewer
    }
  `,
});
