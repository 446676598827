/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddStateManagerInput = {|
  areaManagers?: ?$ReadOnlyArray<?InputAreaManager>,
  emails: $ReadOnlyArray<?string>,
  state: string,
|};
export type InputAreaManager = {|
  email: string,
  id?: ?string,
  stores: $ReadOnlyArray<?string>,
|};
export type AddStateManagerMutationVariables = {|
  input: AddStateManagerInput
|};
export type AddStateManagerMutationResponse = {|
  +addStateManager: ?{|
    +stateManagerEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +emails: ?$ReadOnlyArray<?string>,
        +state: ?string,
        +areaManagers: ?$ReadOnlyArray<?{|
          +id: string,
          +email: ?string,
          +stores: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
        |}>,
      |},
    |}
  |}
|};
export type AddStateManagerMutation = {|
  variables: AddStateManagerMutationVariables,
  response: AddStateManagerMutationResponse,
|};
*/


/*
mutation AddStateManagerMutation(
  $input: AddStateManagerInput!
) {
  addStateManager(input: $input) {
    stateManagerEdge {
      cursor
      node {
        id
        emails
        state
        areaManagers {
          id
          email
          stores {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddStateManagerPayload",
    "kind": "LinkedField",
    "name": "addStateManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StateManagerEdge",
        "kind": "LinkedField",
        "name": "stateManagerEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManager",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emails",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AreaManager",
                "kind": "LinkedField",
                "name": "areaManagers",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "stores",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddStateManagerMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddStateManagerMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e178af293208e3e6f3bf0a842f3c9f8f",
    "id": null,
    "metadata": {},
    "name": "AddStateManagerMutation",
    "operationKind": "mutation",
    "text": "mutation AddStateManagerMutation(\n  $input: AddStateManagerInput!\n) {\n  addStateManager(input: $input) {\n    stateManagerEdge {\n      cursor\n      node {\n        id\n        emails\n        state\n        areaManagers {\n          id\n          email\n          stores {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '95ebdf7d11680971c4adde8702e2ff4a';

module.exports = node;
