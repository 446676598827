/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SubcategoryForm_viewer$ref = any;
type SubcategoryHistory_subcategory$ref = any;
type WebsiteRef_subcategory$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubcategoryView_viewer$ref: FragmentReference;
declare export opaque type SubcategoryView_viewer$fragmentType: SubcategoryView_viewer$ref;
export type SubcategoryView_viewer = {|
  +id: string,
  +subcategories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +urlSlug: ?string,
        +position: ?number,
        +aliases: ?$ReadOnlyArray<?string>,
        +attributes: ?any,
        +metaTitle: ?string,
        +metaDescription: ?string,
        +descriptions: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +description: ?string,
              +description2: ?string,
              +brands: ?$ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
              |}>,
              +categories: ?$ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
              |}>,
            |}
          |}>
        |},
        +metadata: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +metaTitle: ?string,
              +metaDescription: ?string,
              +canonicalUrl: ?{|
                +url: ?string,
                +status: ?boolean,
              |},
              +brands: ?$ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
              |}>,
              +categories: ?$ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
              |}>,
            |}
          |}>
        |},
        +categories: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +childSubcategories: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
        +subcategories: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +autoAssignCategory: ?$ReadOnlyArray<?{|
          +categoryId: ?string,
          +attributeId: ?string,
          +option: ?string,
        |}>,
        +images: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +urlSlug: ?string,
              +mode: ?number,
              +link: ?string,
              +brands: ?$ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
              |}>,
              +categories: ?$ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
              |}>,
              +subcategories: ?$ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
              |}>,
            |}
          |}>
        |},
        +status: ?boolean,
        +displayMode: ?string,
        +visibleFirstDepthOnly: ?boolean,
        +nestBrands: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +nests: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +parents: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +$fragmentRefs: SubcategoryHistory_subcategory$ref & WebsiteRef_subcategory$ref,
      |}
    |}>
  |},
  +$fragmentRefs: SubcategoryForm_viewer$ref,
  +$refType: SubcategoryView_viewer$ref,
|};
export type SubcategoryView_viewer$data = SubcategoryView_viewer;
export type SubcategoryView_viewer$key = {
  +$data?: SubcategoryView_viewer$data,
  +$fragmentRefs: SubcategoryView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaTitle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaDescription",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v6 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Brand",
  "kind": "LinkedField",
  "name": "brands",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "categories",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Subcategory",
  "kind": "LinkedField",
  "name": "subcategories",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubcategoryView_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "SubcategoryConnection",
      "kind": "LinkedField",
      "name": "subcategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubcategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Subcategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "position",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "aliases",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attributes",
                  "storageKey": null
                },
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": (v5/*: any*/),
                  "concreteType": "SubcategoryDescriptionConnection",
                  "kind": "LinkedField",
                  "name": "descriptions",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SubcategoryDescriptionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SubcategoryDescription",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "description",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "description2",
                              "storageKey": null
                            },
                            (v7/*: any*/),
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "descriptions(first:999)"
                },
                {
                  "alias": null,
                  "args": (v5/*: any*/),
                  "concreteType": "SubcategoryMetadataConnection",
                  "kind": "LinkedField",
                  "name": "metadata",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SubcategoryMetadataEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SubcategoryMetadata",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CanonicalUrl",
                              "kind": "LinkedField",
                              "name": "canonicalUrl",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "url",
                                  "storageKey": null
                                },
                                (v9/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v7/*: any*/),
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "metadata(first:999)"
                },
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": (v5/*: any*/),
                  "concreteType": "SubcategoryConnection",
                  "kind": "LinkedField",
                  "name": "childSubcategories",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SubcategoryEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Subcategory",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v6/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "childSubcategories(first:999)"
                },
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoAssignCategory",
                  "kind": "LinkedField",
                  "name": "autoAssignCategory",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "categoryId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "attributeId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "option",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v5/*: any*/),
                  "concreteType": "SubcategoryImageConnection",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SubcategoryImageEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SubcategoryImage",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "mode",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "link",
                              "storageKey": null
                            },
                            (v7/*: any*/),
                            (v8/*: any*/),
                            (v10/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "images(first:999)"
                },
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayMode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "visibleFirstDepthOnly",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Brand",
                  "kind": "LinkedField",
                  "name": "nestBrands",
                  "plural": true,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Category",
                  "kind": "LinkedField",
                  "name": "nests",
                  "plural": true,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Subcategory",
                  "kind": "LinkedField",
                  "name": "parents",
                  "plural": true,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SubcategoryHistory_subcategory"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WebsiteRef_subcategory"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubcategoryForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ab211108cc54574dca49e7fb56bc1f1';

module.exports = node;
